import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Title } from "../../ui"
import { keyPartners } from "../../../constants"
import "./index.scss"

const KeyPartners = () => {
  return (
    <Container className="keyPartners__container">
      <Title header="Our Key Partners" color="BlackRussian" />
      <Row>
        <div className="keyPartners__section">
          {keyPartners.map(keyPartner => (
            <Col key={keyPartner.id} className="keyPartners__col">
              <img src={keyPartner.image} alt="keyPartners image" />
            </Col>
          ))}
        </div>
      </Row>
    </Container>
  )
}

export default KeyPartners
