import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
// import { StaticImage } from "gatsby-plugin-image"
import Button from "../../ui/Button"
import "./index.scss"

import Map from "../../../assets/images/home/map.svg"
import NewBanner from "../../../assets/images/home/new-banner.svg"

const Banner = () => (
  <div className="banner__container">
    <Container fluid>
      <Row>
        <Col style={{ display: "flex" }}>
          <div className="banner__caption">
            <h1>Fin is simply smarter finance for everybody.</h1>
            <h3>
              Together with our partners, we provide credit, savings, insurance and more: fast, convenient and reliable.
            </h3>
            {/* <Link to="/about-us">
              <Button
                title="Learn more"
                customClass="banner__btn"
                type="button"
                size="md"
              />
            </Link> */}
          </div>
        </Col>
        <Col style={{ zIndex: 1 }}>
          <div className="banner__image">
            {/* }<StaticImage
              src="../../../assets/images/home/map.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="map of africa"
              backgroundColor="white"
              placeholder="dominatColor"
              style={{ marginBottom: `1.45rem` }}
            /> */}
            <img
              src={NewBanner}
              alt="map of africa"
              className="banner__imageMap"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Banner
