import React from "react"
import { useWindowDimensions } from "../../hooks"

const CountryCard = ({ title, image, text }) => {
  const styleWidth = useWindowDimensions().width <= 500 ? "17rem" : "406px"

  const card = {
    maxWidth: styleWidth,
    // minWidth: styleWidth,
    borderRadius: "15px",
    border: `0em`,
    boxShadow: `3px 3px 40px #00000019`,
    backgroundColor: `var(--mainWhite)`,
    margin: "1em auto",
    padding: "34px 50px 40px",
  }

  return (
    <div style={card}>
      <img src={image} alt={title} style={imgStyle} />
      <h6 style={titleStyle}>{title}</h6>
    </div>
  )
}

const imgStyle = {
  // height: "8em",
  // width: "100%",
  // objectFit: "contain"
}

const paragraphStyle = {
  padding: ".5em .5em",
  fontSize: "14px",
}

const titleStyle = {
    padding: "26px 0 0",
    fontFamily: '"Noto Sans" sans-serif',
    fontSize: '35px',
}

export default CountryCard
