import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { Title, Button } from "../../ui"
import { displayLoadingMessage } from "../../../utils"
import { myContext } from "../../../provider"
import NewsCard from "../../News/NewsCard"
import "./index.scss"

const LatestNews = () => {
  const context = useContext(myContext)

  const { fetchMediumPosts } = context

  useEffect(async () => {
    await fetchMediumPosts()
  }, [])

  return (
    <div className="latestNews__container">
      <Container>
        <Title header="The latest from us" color="BlackRussian" />
        <myContext.Consumer>
          {context => (
            <>
              {context.newsIsLoading && <p>{displayLoadingMessage}</p>}
              {context.newsError && (
                <p style={error}>Error: {context.newsError}</p>
              )}
              <div className="latestNews__section">
                {context.posts?.slice(0, 3).map(post => {
                  const { guid, thumbnail, title, link, description } = post

                  return (
                    <NewsCard
                      {...{
                        key: guid,
                        thumbnail,
                        title,
                        description,
                        link,
                      }}
                    />
                  )
                })}
              </div>
              <div className="latestNews__bottom">
                <Link to="/news">
                  <Button
                    title="View more"
                    customClass="latestNews__btn"
                    type="button"
                    size="md"
                  />
                </Link>
              </div>
            </>
          )}
        </myContext.Consumer>
      </Container>
    </div>
  )
}

const error = {
  color: "var(--mainError)",
  fontWeight: 600,
}

export default LatestNews
