import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import CountUp from "react-countup"
import { Title } from "../../ui"
import { growth } from "../../../constants"
import "./index.scss"

const Growth = () => {
  return (
    <div className="growth__container">
      <Container>
        <Title header="Growth in numbers" color="White" />
        <Row>
          <div className="growth__section">
            {growth.map(data => (
              <Col key={data.id}>
                <img src={data.image} alt="text" />
                <br />
                <br />
                <CountUp
                  delay={0}
                  prefix={data.prefix}
                  suffix={data.suffix}
                  className="growth__countUp"
                  end={parseInt(data.number)}
                  duration={5}
                />
                <p>{data.text}</p>
              </Col>
            ))}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Growth
