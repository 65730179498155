import React from "react"
import { Container } from "react-bootstrap"
import { Title } from "../../ui"
import CountryCard from "../../Global/CountryCard"
import "./index.scss"

import SouthAfrica from "../../../assets/images/country-picker/map1.svg"
import Kenya from "../../../assets/images/country-picker/map2.svg"
import Tanzania from "../../../assets/images/country-picker/map3.svg"

const CountryPicker = () => {
  const countries = [
    {
      title: "South Africa",
      image: SouthAfrica,
      link: "http://za.fin.africa/",
    },
    {
      title: "Kenya",
      image: Kenya,
      link: "http://ke.fin.africa/",
    },
    {
      title: "Tanzania",
      image: Tanzania,
      link: "http://tz.fin.africa/",
    },
  ]

  return (
    <Container className="countrypicker__container">
      <h3>Fin has a footprint across Africa.</h3>
      <h1>Pick your country.</h1>
      <div className="countrypicker__section">
        {countries.map((data, index) => (
          <a key={index} href={data.link} target="_blank" className="county__holder">
            <CountryCard title={data.title} image={data.image} />
          </a>
        ))}
      </div>
    </Container>
  )
}

export default CountryPicker
