import React from "react"
import { Container } from "react-bootstrap"
import { Title, Button } from "../../ui"
import FacebookLogo from "../../../assets/images/home/facebook.svg"
import FacebookLogoHover from "../../../assets/images/home/facebook-hover.svg"
import TwitterLogo from "../../../assets/images/home/twitter.svg"
import TwitterLogoHover from "../../../assets/images/home/twitter-hover.svg"
import LinkedInLogo from "../../../assets/images/home/linked-in.svg"
import LinkedInLogoHover from "../../../assets/images/home/linked-in-hover.svg"
import "./index.scss"

const MailUs = () => {
  const emailAddress = process.env.GATSBY_EMAIL_ADDRESS
  const facebookUrl = process.env.GATSBY_FACEBOOK_URL
  const linkedInUrl = process.env.GATSBY_LINKEDIN_URL
  const twitterUrl = process.env.GATSBY_TWITTER_URL

  return (
    <Container className="mailUs__container">
      <Title header="We would love to hear from you!" color="BlackRussian" />
      <p>Join us on our quest to build Africa’s best fintech products</p>
      <a
        href={`mailto:${emailAddress}`}
        title={emailAddress}
        className="mailUs__mail"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          {...{
            title: "Mail us",
            type: "button",
            customClass: "mailUs__button",
          }}
        />
      </a>
      <div className="mailUs__socialLinks">
        <a
          href={linkedInUrl}
          title={linkedInUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={LinkedInLogo}
            alt="linkedIn logo"
            onMouseOver={e => (e.currentTarget.src = LinkedInLogoHover)}
            onMouseOut={e => (e.currentTarget.src = LinkedInLogo)}
          />
        </a>
        <a
          href={twitterUrl}
          title={twitterUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={TwitterLogo}
            alt="twitter logo"
            onMouseOver={e => (e.currentTarget.src = TwitterLogoHover)}
            onMouseOut={e => (e.currentTarget.src = TwitterLogo)}
          />
        </a>
        <a
          href={facebookUrl}
          title={facebookUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={FacebookLogo}
            alt="facebook logo"
            onMouseOver={e => (e.currentTarget.src = FacebookLogoHover)}
            onMouseOut={e => (e.currentTarget.src = FacebookLogo)}
          />
        </a>
      </div>
    </Container>
  )
}

export default MailUs
