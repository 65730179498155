import React from "react"
import SignUpToNewsLetter from "../Global/SignUpToNewsLetter"
import Growth from "./Growth"
import MailUs from "./MailUs"
import Inclusion from "./Inclusion"
import LatestNews from "./LatestNews"
import Banner from "./Banner"
import KeyPartners from "./KeyPartners"
import CountryPicker from "./CountryPicker"

function Home() {
  return (
    <div>
      <Banner />
      <CountryPicker />
      <Growth />
      <KeyPartners />
      <LatestNews />
      <MailUs />
      {/* <SignUpToNewsLetter /> */}
    </div>
  )
}

export default Home
