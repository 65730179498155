import React from "react"
import { Container } from "react-bootstrap"
import { Title } from "../../ui"
import { inclusionData } from "../../../constants"
import InclusionCard from "../../Global/InclusionCard"
import "./index.scss"

const Inclusion = () => {
  return (
    <Container className="inclusion__container">
      <Title header="Our Products & Services" color="BlackRussian" />
      <p>
        We are on a mission to <b>enhance the lives of our customers through simple, convenient,
          <br />
          and appropriate financial services</b>. Our credit-led product offering drives financial
        <br />
        inclusion through innovation, integrity, teamwork, and our belief in people.
      </p>
      <div className="inclusion__section">
        {inclusionData.map(data => (
          <div key={data.id}>
            <InclusionCard
              title={data.title}
              image={data.image}
              text={data.text}
            />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Inclusion
